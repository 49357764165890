<template><div></div></template>

<script>
export default {
  name: 'FacebookRegistration',
  data () {
    return {
      facebookToken: {
        code: null,
        state: null
      }
    }
  },
  created () {
    this.facebookToken.code = this.$route.query.code
    this.facebookToken.state = this.$route.query.state
  },
  mounted () {
    this.lockScreen()
    this.$store.dispatch('getFacebookLoginUrl', this.facebookToken)
      .then(() => {
        this.$router.push({ name: 'dashboard' })
      })
      .catch(() => {
        this.$router.push({ name: 'login' })
      })
  }
}
</script>
